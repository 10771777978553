import { Dispatch, SetStateAction } from "react";

import { AuthState } from "types";

import PhoneAuth from "components/login/PhoneAuth";
import AuthSection from "components/login/AuthSection";
import AuthCard from "components/login/AuthCard";

interface Props {
    authState: Array<AuthState>;
    setAuthState: Dispatch<SetStateAction<AuthState>>;
    buttonText: string;
}

const PhoneAuthCard = ({ authState, setAuthState, buttonText }: Props) => {
    return (
        <AuthSection>
            <AuthCard>
                <div className="flex flex-col self-stretch grow justify-center items-center gap-12 p-14">
                    <p className="self-stretch text-center text-2xl font-bold break-words">
                        Log in or sign up
                    </p>
                    <PhoneAuth
                        callbackFn={setAuthState}
                        args={authState}
                        buttonText={buttonText}
                    />
                </div>
            </AuthCard>
        </AuthSection>
    );
};

export default PhoneAuthCard;
