import React from "react";
import * as DialogRadix from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";

import { useUITheme } from "hooks/useUITheme";

import { IconsMap } from "components/ui/Icon";
import Button from "components/ui/Button";
import { ButtonMode } from "types";

import { core_palette } from "utilities/palette";

type Props = {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    phone: string;
    code: string;
    setCode: (code: string) => void;
    onSubmit: () => void;
    fetching: boolean;
};

const PhoneVerifyModal = ({
    open,
    onOpenChange,
    phone,
    code,
    setCode,
    onSubmit,
    fetching,
}: Props) => {
    const theme = useUITheme();

    // INFO: Prevent form submission from reloading the page to allow us to decide how to handle the onSubmit action.
    const _onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit();
    };

    return (
        <DialogRadix.Root open={open} onOpenChange={onOpenChange}>
            <DialogRadix.Portal>
                <DialogRadix.Overlay className="bg-blackA9 data-[state=open]:animate-fadeIn fixed inset-0" />
                <DialogRadix.Content
                    className="data-[state=open]:animate-dialogContentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-xl shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none"
                    style={{
                        backgroundColor: theme.background_color,
                    }}
                >
                    <div className="flex self-stretch content-between items-center border-b-[1px]">
                        <div className="flex items-start p-2">
                            <DialogRadix.Close asChild>
                                <button
                                    className="flex justify-center items-center p-3 rounded-lg hover:brightness-90 duration-200 transition-all cursor-pointer"
                                    aria-label="Close"
                                >
                                    <Cross2Icon className="h-5 w-5" />
                                </button>
                            </DialogRadix.Close>
                        </div>
                        <div className="flex grow justify-center items-center">
                            <DialogRadix.Title className="text-base font-semibold">
                                Verify your number
                            </DialogRadix.Title>
                        </div>
                        <div className="flex items-start p-2">
                            <button className="flex justify-center items-center p-3 rounded-lg">
                                <IconsMap.no_icon />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col self-stretch items-start gap-6 p-6">
                        <DialogRadix.Description className="self-stretch text-base font-normal">
                            Enter the code sent to {phone}
                        </DialogRadix.Description>
                        <form
                            className="flex flex-col self-stretch"
                            onSubmit={_onFormSubmit}
                        >
                            <input
                                className="flex items-center self-stretch gap-2 px-[10px] py-3 border-2 rounded-lg text-base font-normal border-black duration-200 transition-all focus:shadow-[0_0_0_1px_rgba(0,0,0,.4)]"
                                id="code"
                                name="code"
                                placeholder="Enter your code"
                                autoComplete="one-time-code"
                                value={code}
                                onChange={e => setCode(e.target.value)}
                                autoFocus
                            />
                        </form>
                        <Button
                            fetching={fetching}
                            id="login-button"
                            title="Continue"
                            backgroundColor={core_palette.success.light}
                            onClick={onSubmit}
                            className="self-stretch rounded-lg"
                            textClassName="text-base font-medium"
                            color={core_palette.inverse.light}
                        />
                    </div>
                </DialogRadix.Content>
            </DialogRadix.Portal>
        </DialogRadix.Root>
    );
};

export default PhoneVerifyModal;
