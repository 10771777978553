import { ReactNode } from "react";

interface Props {
    children: ReactNode;
}

const AuthCard = ({ children }: Props) => {
    return (
        <div className="flex flex-col items-start gap-[10px] w-full sm:w-[500px] min-h-[800px] max-h-full sm:p-6 sm:border rounded-xl">
            {children}
        </div>
    );
};

export default AuthCard;
