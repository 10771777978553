import { AuthAction, useAuthUser, withAuthUser } from "next-firebase-auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import { toast } from "sonner";

import { AuthState } from "types";

import { setError, useErrorMessage } from "store/errorSlice";

import Loading from "components/ui/Loading";
import PhoneAuthCard from "components/login/PhoneAuthCard";
import LoadingPage from "components/ui/LoadingPage";

function Login() {
    const dispatch = useDispatch();
    const { firebaseUser } = useAuthUser();
    const globalError = useErrorMessage();

    const [authState, setAuthState] = useState<AuthState>(AuthState.loading);

    useEffect(() => {
        if (globalError) {
            toast.error(`Error: ${globalError}`);
        }

        return () => {
            dispatch(setError(null));
        };
    }, [dispatch, globalError]);

    useEffect(() => {
        if (firebaseUser) {
            setAuthState(AuthState.complete);
        } else {
            setAuthState(AuthState.auth);
        }
    }, [firebaseUser]);

    switch (authState) {
        case AuthState.loading:
            return <Loading />;
        case AuthState.auth:
            return (
                <PhoneAuthCard
                    authState={[AuthState.complete]}
                    setAuthState={setAuthState}
                    buttonText="Continue"
                />
            );
        case AuthState.complete:
            // INFO: Redirection handled in the auth wrapper.
            return null;
        default:
            return <Loading />;
    }
}

export default withAuthUser({
    whenAuthed: AuthAction.REDIRECT_TO_APP,
    appPageURL: "/admin",
    whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
    whenUnauthedAfterInit: AuthAction.RENDER,
    LoaderComponent: LoadingPage,
})(Login);
