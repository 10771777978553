import {
    RecaptchaVerifier,
    getAuth,
    signInWithPhoneNumber,
} from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { StatusCodes } from "http-status-codes";
import { z } from "zod";
import { toast } from "sonner";

import PhoneVerifyModal from "components/create/PhoneVerifyModal";
import Button from "components/ui/Button";

import { core_palette } from "utilities/palette";
import UIButton from "components/ui/UIButton";

// Create LoadingIcon component using CircularProgress
const LoadingIcon = () => <p>loading</p>;
interface Props {
    buttonText?: string;
    args?: Array<string | number | boolean>;
    callbackFn?: Function;
}

const phoneNumber = z.object({
    number: z.string({
        required_error: "Phone number is required",
        invalid_type_error: "Phone number must be a string",
    }),
});

const PhoneAuth = ({ buttonText, args, callbackFn }: Props) => {
    // INFO: Please refer to the official Firebase docs for our reCAPTCHA implementation.
    //       Source: https://firebase.google.com/docs/auth/web/phone-auth
    const auth = getAuth();

    const [phone, setPhone] = useState<string | null>(null);
    const [openPhoneVerifyModal, setOpenPhoneVerifyModal] = useState(false);
    const [code, setCode] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        // INFO: Prevent reCAPTCHA has already been rendered in this element error (ssr).
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                "login-button",
                {
                    size: "invisible",
                    callback: () => {},
                },
                auth,
            );
        }
    }, [auth]);

    // INFO: Prevent form submission from reloading the page to allow us to decide how to handle the onSubmit action.
    const _onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        _onSubmit();
    };

    const _onSubmit = () => {
        setIsSubmitting(true); // Set isSubmitting to true when button is clicked
        try {
            const { number } = phoneNumber.parse({ number: phone });
            _sendCode(number);
        } catch (err: any) {
            toast.error(`${err.name}: ${err.message}`);
            setPhone(null);
            setIsSubmitting(false); // Reset isSubmitting if there's an error
        }
    };

    const _sendCode = useCallback(
        (phone: string) => {
            signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
                .then(confirmationResult => {
                    // INFO: SMS sent. Prompt user to type the code from the message, then sign the
                    //       user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;

                    setOpenPhoneVerifyModal(true);
                    setIsSubmitting(false); // Reset isSubmitting after SMS is sent
                })
                .catch((error: any) => {
                    toast.error(`${error.name}: ${error.message}`);

                    // INFO: If sign-in errors, reset the reCAPTCHA so the user can try again.
                    window.recaptchaVerifier
                        .render()
                        .then(function (widgetId: any) {
                            grecaptcha.reset(widgetId);
                        });
                    setIsSubmitting(false); // Reset isSubmitting if there's an error
                });
        },
        [auth],
    );

    const _onVerifyCode = useCallback(async () => {
        setIsSubmitting(true);
        try {
            const result = await window.confirmationResult.confirm(code);

            const user = result.user;

            const response = await fetch("/api/v0.1/users", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    phone_number: user.phoneNumber,
                    uid: user.uid,
                }),
            });

            const data = await response.json();

            if (response.status !== StatusCodes.OK) {
                throw new Error(data.message);
            }

            // INFO: Callback function to run after successful authentication.
            if (callbackFn && args) {
                callbackFn(...args);
            }
        } catch (err: any) {
            toast.error(`${err.name}: ${err.message}`);
        } finally {
            setIsSubmitting(false);
        }
    }, [args, callbackFn, code]);

    return (
        <div className="flex flex-col self-stretch items-start gap-4">
            <PhoneVerifyModal
                open={openPhoneVerifyModal}
                onOpenChange={setOpenPhoneVerifyModal}
                phone={phone || ""}
                code={code}
                setCode={setCode}
                onSubmit={_onVerifyCode}
                fetching={isSubmitting}
            />
            <form
                className="flex flex-col self-stretch"
                onSubmit={_onFormSubmit}
            >
                <PhoneInput
                    placeholder="Phone Number"
                    value={phone || ""}
                    onChange={(value: string) => {
                        setPhone(value);
                    }}
                    defaultCountry="US"
                    className="flex items-center self-stretch gap-2 px-[10px] py-3 border-2 rounded-lg border-black"
                />
            </form>
            <UIButton
                id="login-button"
                title={buttonText} // Show LoadingIcon if isSubmitting is true, else show buttonText
                backgroundColor={core_palette.success.light}
                onClick={_onSubmit}
                className="self-stretch"
                borderRadius="12px"
                textClassName="text-base font-medium"
                color={core_palette.inverse.light}
                disabled={isSubmitting}
                fetching={isSubmitting}
            />
        </div>
    );
};

export default PhoneAuth;
